import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Image, Section, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Vibrant Ventures
			</title>
			<meta name={"description"} content={"Your choice for memorable moments"} />
			<meta property={"og:title"} content={"Vibrant Ventures"} />
			<meta property={"og:description"} content={"Your choice for memorable moments"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65e71766009abe001f042fa9/images/3-2.jpg?v=2024-03-05T15:51:48.520Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65e71766009abe001f042fa9/images/3-2.jpg?v=2024-03-05T15:51:48.520Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65e71766009abe001f042fa9/images/3-2.jpg?v=2024-03-05T15:51:48.520Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65e71766009abe001f042fa9/images/3-2.jpg?v=2024-03-05T15:51:48.520Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65e71766009abe001f042fa9/images/3-2.jpg?v=2024-03-05T15:51:48.520Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65e71766009abe001f042fa9/images/3-2.jpg?v=2024-03-05T15:51:48.520Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65e71766009abe001f042fa9/images/3-2.jpg?v=2024-03-05T15:51:48.520Z"} />
		</Helmet>
		<Components.Header2>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="text" />
		</Components.Header2>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-15">
			<Override slot="SectionContent" flex-wrap="wrap" flex-direction="row" />
			<Box
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 0px 16px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				display="flex"
				flex-direction="column"
				lg-width="100%"
				justify-content="space-between"
			>
				<Box display="flex" flex-direction="column">
					<Text
						margin="0px 0px 36px 0px"
						color="--darkL2"
						font="--headline2"
						lg-text-align="center"
						sm-font="--headline3"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
						md-text-align="left"
					>
						Making Your Special Moments Unforgettable
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--darkL2"
						font="--base"
						lg-text-align="center"
						md-text-align="left"
					>
						From intimate birthday celebrations to bustling conferences and heartfelt small concerts, we tailor each event to reflect your vision and leave an indelible impression. We take the hassle out of event planning and bring your dreams to life.
					</Text>
					<Link
						href="/about"
						padding="12px 24px 12px 24px"
						color="--light"
						text-decoration-line="initial"
						font="--lead"
						border-radius="8px"
						margin="2rem 16px 0px 0px"
						transition="background-color 0.2s ease-in-out 0s"
						sm-margin="0px 0px 16px 0px"
						sm-text-align="center"
						background="--color-primary"
						hover-transition="background-color 0.2s ease-in-out 0s"
						hover-background="--color-primary"
						text-align="center"
					>
						Learn More
					</Link>
				</Box>
				<Box width="100%" display="flex" sm-flex-wrap="wrap">
					<Box
						display="flex"
						width="25%"
						lg-width="100%"
						margin="0px 0px 0px 0px"
						padding="16px 16px 16px 16px"
						flex-direction="column"
						sm-width="50%"
					>
						<Box
							overflow-x="hidden"
							overflow-y="hidden"
							position="relative"
							transform="translateY(0px)"
							hover-transform="translateY(-10px)"
							width="100%"
							height="auto"
							transition="transform 0.2s ease-in-out 0s"
							padding="0px 0px 125% 0px"
						>
							<Image
								width="100%"
								left={0}
								src="https://uploads.quarkly.io/65e71766009abe001f042fa9/images/4-1.jpg?v=2024-03-05T15:51:48.529Z"
								object-fit="cover"
								position="absolute"
								display="block"
								top="auto"
								right={0}
								bottom="0px"
								min-height="100%"
								srcSet="https://smartuploads.quarkly.io/65e71766009abe001f042fa9/images/4-1.jpg?v=2024-03-05T15%3A51%3A48.529Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65e71766009abe001f042fa9/images/4-1.jpg?v=2024-03-05T15%3A51%3A48.529Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65e71766009abe001f042fa9/images/4-1.jpg?v=2024-03-05T15%3A51%3A48.529Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65e71766009abe001f042fa9/images/4-1.jpg?v=2024-03-05T15%3A51%3A48.529Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65e71766009abe001f042fa9/images/4-1.jpg?v=2024-03-05T15%3A51%3A48.529Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65e71766009abe001f042fa9/images/4-1.jpg?v=2024-03-05T15%3A51%3A48.529Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65e71766009abe001f042fa9/images/4-1.jpg?v=2024-03-05T15%3A51%3A48.529Z&quality=85&w=3200 3200w"
								sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
							/>
						</Box>
					</Box>
					<Box
						width="25%"
						lg-width="100%"
						margin="0px 0px 0px 0px"
						padding="16px 16px 16px 16px"
						flex-direction="column"
						sm-width="50%"
						display="flex"
					>
						<Box
							width="100%"
							height="auto"
							position="relative"
							padding="0px 0px 125% 0px"
							overflow-x="hidden"
							overflow-y="hidden"
							transform="translateY(0px)"
							transition="transform 0.2s ease-in-out 0s"
							hover-transform="translateY(-10px)"
						>
							<Image
								display="block"
								width="100%"
								top="auto"
								right={0}
								position="absolute"
								object-fit="cover"
								left={0}
								bottom="0px"
								min-height="100%"
								src="https://uploads.quarkly.io/65e71766009abe001f042fa9/images/2-3.jpg?v=2024-03-05T15:51:48.522Z"
								srcSet="https://smartuploads.quarkly.io/65e71766009abe001f042fa9/images/2-3.jpg?v=2024-03-05T15%3A51%3A48.522Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65e71766009abe001f042fa9/images/2-3.jpg?v=2024-03-05T15%3A51%3A48.522Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65e71766009abe001f042fa9/images/2-3.jpg?v=2024-03-05T15%3A51%3A48.522Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65e71766009abe001f042fa9/images/2-3.jpg?v=2024-03-05T15%3A51%3A48.522Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65e71766009abe001f042fa9/images/2-3.jpg?v=2024-03-05T15%3A51%3A48.522Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65e71766009abe001f042fa9/images/2-3.jpg?v=2024-03-05T15%3A51%3A48.522Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65e71766009abe001f042fa9/images/2-3.jpg?v=2024-03-05T15%3A51%3A48.522Z&quality=85&w=3200 3200w"
								sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
							/>
						</Box>
					</Box>
					<Box
						flex-direction="column"
						sm-width="50%"
						display="flex"
						width="25%"
						lg-width="100%"
						margin="0px 0px 0px 0px"
						padding="16px 16px 16px 16px"
					>
						<Box
							overflow-x="hidden"
							overflow-y="hidden"
							position="relative"
							transition="transform 0.2s ease-in-out 0s"
							hover-transform="translateY(-10px)"
							height="auto"
							transform="translateY(0px)"
							padding="0px 0px 125% 0px"
							width="100%"
						>
							<Image
								object-fit="cover"
								width="100%"
								right={0}
								bottom="0px"
								min-height="100%"
								src="https://uploads.quarkly.io/65e71766009abe001f042fa9/images/1-2.jpg?v=2024-03-05T15:51:48.519Z"
								position="absolute"
								display="block"
								top="auto"
								left={0}
								srcSet="https://smartuploads.quarkly.io/65e71766009abe001f042fa9/images/1-2.jpg?v=2024-03-05T15%3A51%3A48.519Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65e71766009abe001f042fa9/images/1-2.jpg?v=2024-03-05T15%3A51%3A48.519Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65e71766009abe001f042fa9/images/1-2.jpg?v=2024-03-05T15%3A51%3A48.519Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65e71766009abe001f042fa9/images/1-2.jpg?v=2024-03-05T15%3A51%3A48.519Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65e71766009abe001f042fa9/images/1-2.jpg?v=2024-03-05T15%3A51%3A48.519Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65e71766009abe001f042fa9/images/1-2.jpg?v=2024-03-05T15%3A51%3A48.519Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65e71766009abe001f042fa9/images/1-2.jpg?v=2024-03-05T15%3A51%3A48.519Z&quality=85&w=3200 3200w"
								sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
							/>
						</Box>
					</Box>
					<Box
						display="flex"
						width="25%"
						lg-width="100%"
						margin="0px 0px 0px 0px"
						padding="16px 16px 16px 16px"
						flex-direction="column"
						sm-width="50%"
					>
						<Box
							position="relative"
							transition="transform 0.2s ease-in-out 0s"
							width="100%"
							height="auto"
							overflow-y="hidden"
							padding="0px 0px 125% 0px"
							overflow-x="hidden"
							transform="translateY(0px)"
							hover-transform="translateY(-10px)"
						>
							<Image
								object-fit="cover"
								position="absolute"
								width="100%"
								left={0}
								bottom="0px"
								src="https://uploads.quarkly.io/65e71766009abe001f042fa9/images/1-1.jpg?v=2024-03-05T15:51:48.496Z"
								display="block"
								top="auto"
								right={0}
								min-height="100%"
								srcSet="https://smartuploads.quarkly.io/65e71766009abe001f042fa9/images/1-1.jpg?v=2024-03-05T15%3A51%3A48.496Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65e71766009abe001f042fa9/images/1-1.jpg?v=2024-03-05T15%3A51%3A48.496Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65e71766009abe001f042fa9/images/1-1.jpg?v=2024-03-05T15%3A51%3A48.496Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65e71766009abe001f042fa9/images/1-1.jpg?v=2024-03-05T15%3A51%3A48.496Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65e71766009abe001f042fa9/images/1-1.jpg?v=2024-03-05T15%3A51%3A48.496Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65e71766009abe001f042fa9/images/1-1.jpg?v=2024-03-05T15%3A51%3A48.496Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65e71766009abe001f042fa9/images/1-1.jpg?v=2024-03-05T15%3A51%3A48.496Z&quality=85&w=3200 3200w"
								sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
							/>
						</Box>
					</Box>
				</Box>
			</Box>
			<Box
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
			>
				<Box
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					position="relative"
					transform="translateY(0px)"
					padding="0px 0px 90% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
				>
					<Image
						src="https://uploads.quarkly.io/65e71766009abe001f042fa9/images/3-2.jpg?v=2024-03-05T15:51:48.520Z"
						object-fit="cover"
						left={0}
						right={0}
						min-height="auto"
						position="absolute"
						display="block"
						width="100%"
						top="auto"
						bottom="0px"
						height="100%"
						srcSet="https://smartuploads.quarkly.io/65e71766009abe001f042fa9/images/3-2.jpg?v=2024-03-05T15%3A51%3A48.520Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65e71766009abe001f042fa9/images/3-2.jpg?v=2024-03-05T15%3A51%3A48.520Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65e71766009abe001f042fa9/images/3-2.jpg?v=2024-03-05T15%3A51%3A48.520Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65e71766009abe001f042fa9/images/3-2.jpg?v=2024-03-05T15%3A51%3A48.520Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65e71766009abe001f042fa9/images/3-2.jpg?v=2024-03-05T15%3A51%3A48.520Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65e71766009abe001f042fa9/images/3-2.jpg?v=2024-03-05T15%3A51%3A48.520Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65e71766009abe001f042fa9/images/3-2.jpg?v=2024-03-05T15%3A51%3A48.520Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0\n\npx 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Why Choose Vibrant Ventures?
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					<Strong>
						Personalized Approach.
					</Strong>
					{" "}We pride ourselves on attention to detail and a personalized approach, making each event as unique as our clients.
					<br />
					<Strong>
						Creative Solutions.{" "}
					</Strong>
					Our team strives for creativity and offers innovative ideas to make each event special.
					<br />
					<Strong>
						Seamless Planning.
					</Strong>
					{" "}From concept to execution, we ensure a stress-free planning process, allowing you to enjoy the event as much as your guests.
					<br />
					<Strong>
						Local Flavors.
					</Strong>
					{" "}Embracing the essence of your chosen venue, we combine local charm and tastes to offer authentic experiences.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://uploads.quarkly.io/65e71766009abe001f042fa9/images/2-2.jpg?v=2024-03-05T15:51:48.513Z"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
						srcSet="https://smartuploads.quarkly.io/65e71766009abe001f042fa9/images/2-2.jpg?v=2024-03-05T15%3A51%3A48.513Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65e71766009abe001f042fa9/images/2-2.jpg?v=2024-03-05T15%3A51%3A48.513Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65e71766009abe001f042fa9/images/2-2.jpg?v=2024-03-05T15%3A51%3A48.513Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65e71766009abe001f042fa9/images/2-2.jpg?v=2024-03-05T15%3A51%3A48.513Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65e71766009abe001f042fa9/images/2-2.jpg?v=2024-03-05T15%3A51%3A48.513Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65e71766009abe001f042fa9/images/2-2.jpg?v=2024-03-05T15%3A51%3A48.513Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65e71766009abe001f042fa9/images/2-2.jpg?v=2024-03-05T15%3A51%3A48.513Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="--color-dark">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
				padding="0px 80px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 0px 0px" color="--orange" font="--lead" md-margin="0px 0px 20px 0px">
					Bring Your Events to Life
				</Text>
				<Text margin="0px 0px 0px 0px" color="--light" font="--headline2" md-margin="0px 0px 30px 0px">
					What Makes Us Unique?
				</Text>
			</Box>
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
			>
				<Text margin="0px 0px 40px 0px" color="--light" font="--base">
					Immerse yourself in the world of bespoke event planning with Vibrant Ventures. Our commitment to personalization combined with a knack for creativity sets us apart. We believe in creating experiences, not just events, with a keen focus on bringing your vision to life in the most vibrant way possible.
				</Text>
			</Box>
		</Section>
		<Section padding="100px 0 100px 0" background="--color-light" quarkly-title="FAQ-10">
			<Box min-width="100px" min-height="100px" display="flex" lg-flex-direction="column">
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 600 36px/1.2 --fontFamily-sans" color="--darkL1">
						Frequently Asked Questions
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="block"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="50px 50px"
					md-grid-template-columns="1fr"
					sm-grid-gap="35px 0"
					padding="0px 0px 0px 70px"
					lg-padding="0px 0px 0px 0"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							What types of events do you specialize in?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							We specialize in a wide range of events, including small concerts, birthday parties, conferences, weddings, and more, each crafted to create unique and unforgettable experiences.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							How far in advance should I book an event?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							We recommend booking as early as possible to ensure availability of your desired date and to allow ample time for planning and customization.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							Can you accommodate dietary restrictions at events?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Absolutely! Our catering partners are versatile and can craft menus that cater to various dietary needs, ensuring all guests have delightful culinary experiences.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							What is your approach to event planning and execution?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Our approach is deeply collaborative and personalized. We work closely with you to understand your vision and preferences, ensuring every aspect of the event is meticulously planned to perfection.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							How do you handle unexpected changes or last-minute requests?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							We are adaptable and resourceful, with the ability to effectively manage unexpected changes or last-minute requests, ensuring your event runs smoothly without any hiccups.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							Do you offer event themes and decor personalization?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Yes, we pride ourselves on our ability to bring your vision to life through creative themes and meticulously planned decor that reflect the essence of your event.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="link" />
			<Override slot="text6" />
			<Override slot="text" />
			<Override slot="text2" />
			<Override slot="text3" />
			<Override slot="text1" />
			<Override slot="link1" />
			<Override slot="text4" />
			<Override slot="text5" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65bb720efdcae00021dd9fe2"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});